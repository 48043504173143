import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, useMediaQuery } from '@mui/material';

// project imports
import { AuthWrapper1 } from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import Logo from 'ui-component/Logo';
import { Box } from '@mui/system';
import JoinWithInviteLink from './auth-forms/JoinWithInviteLink';
import AuthFooter from 'ui-component/cards/AuthFooter';
import { useState } from 'react';
import { Link as Linker } from '@mui/material';
import { ReactComponent as AllSet } from '../../../assets/images/icons/allset.svg';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'react-redux';

// ============================|| AUTH3 - FORGOT PASSWORD ||============================ //

const JoinCompany = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    let location = useLocation();

    const search = location.search;
    const cid = new URLSearchParams(search).get('cid');
    console.log(cid);
    const [isTryAgain, setTryAgain] = useState(false);
    const [success, setSuccess] = useState<boolean>(false);

    const firstPart = !isTryAgain
        ? `First, let's find your employer-provided account. Enter your work email address below:`
        : 'We were unable to validate your account. Please double check that you used your work email address, and if the problem persists reach out to your employer or ';

    const dispatch = useDispatch();
    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '90vh' }}>
                <Grid item sx={{ m: 3, ml: 8 }}>
                    <Link to="#">
                        <Box sx={{ display: matchDownSM ? 'none' : 'block' }}>
                            {' '}
                            <Logo dark />
                        </Box>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3, display: matchDownSM ? 'block' : 'none' }}>
                                        <Link to="#">
                                            <Box>
                                                {' '}
                                                <Logo dark />
                                            </Box>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" justifyContent="center" textAlign="center" spacing={2}>
                                            <Grid item xs={12}>
                                                <AllSet />
                                                <Typography color="#002443" gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                                    {success ? 'All Set!' : !isTryAgain ? 'Welcome to BrightUp!' : "Let's try that again"}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    fontSize="16px"
                                                    color="#000"
                                                    sx={{ width: '100px', lineHeight: '30px', fontSize: '15px', fontWeight: '400' }}
                                                    textAlign={matchDownSM ? 'center' : 'inherit'}
                                                >
                                                    <>
                                                        <Typography component="span">{success ? '' : firstPart}</Typography>
                                                        {isTryAgain && (
                                                            <Typography
                                                                // color="secondary"
                                                                component={Linker}
                                                                href="mailto:hi@getbrightup.com"
                                                                target="_blank"
                                                                underline="hover"
                                                                color="secondary"
                                                            >
                                                                {'hi@getbrightup.com'}
                                                            </Typography>
                                                        )}
                                                        <br />
                                                    </>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <JoinWithInviteLink
                                            onVerificationStatus={(status, msg) => {
                                                if (msg === '') {
                                                    setTryAgain(!status);
                                                } else if (msg === undefined) {
                                                    dispatch(
                                                        openSnackbar({
                                                            open: true,
                                                            message: "This email doesn't exist or it is associated with another company.",
                                                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                                            variant: 'alert',
                                                            severity: 'error',
                                                            alert: {
                                                                color: 'error'
                                                            },
                                                            close: false
                                                        })
                                                    );
                                                }
                                            }}
                                            onUpdateSuccess={(value: boolean) => {
                                                setSuccess(value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 0, mt: 1, position: 'fixed', bottom: 0, width: '100%', paddingX: 10 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default JoinCompany;
