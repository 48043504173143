// material-ui
import { styled } from '@mui/material/styles';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper1 = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
    minHeight: '100vh'
}));

const ClientRegistrationWrapper = styled('div')(({ theme }) => ({
    backgroundColor: '#fff',
    minHeight: '100vh'
}));

export { AuthWrapper1, ClientRegistrationWrapper };
