import { useEffect, useState } from 'react';

// material-ui
import { Button, Grid, IconButton, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material';

// project imports
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RestoreIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { PatternFormat } from 'react-number-format';
import { DatePicker } from '@mui/x-date-pickers';

import { accountSettingSelector } from 'store/slices/accountSetting';
import { useSelector } from 'store';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import dayjsBusinessTime from 'dayjs-business-time';
import { Box } from '@mui/system';

// Attach dayjs plugin
dayjs.extend(dayjsBusinessTime);
// ==============================|| ADD ITEM PAGE ||============================== //

const dateTimePaperPropsStyles: any = {
    sx: {
        '.MuiPickersCalendarHeader-label': {
            color: 'black !important'
        }
    }
};

const AddItemPage = ({ handleAddItem, setAddItemClicked, edit, editUser, clearEdit, setEditIndex, share }: any) => {
    const obj = edit[0];

    const {
        departmentList: {
            data: { departmentSetting, departments }
        }
    } = useSelector(accountSettingSelector);

    const [formattedNumber, setFormattedNumber] = useState<any>('');
    const [user, setUser] = useState<any>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        department: '',
        sendInvite: null
    });

    useEffect(() => {
        if (editUser) {
            setUser(obj);
        } else {
            setUser({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                department: '',
                sendInvite: null
            });
        }
    }, [editUser]);
    const [errors, setErrors] = useState<any>({
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        phoneNumberError: '',
        departmentError: ''
    });

    const { firstName, lastName, email, phoneNumber, department, sendInvite } = user;
    const { firstNameError, lastNameError, emailError, phoneNumberError, departmentError } = errors;
    const emailRgx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRgx = /^[a-zA-Z]+$/;
    const now = new Date();

    const handleChange = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        if (name === 'phoneNumber' || name === 'department' || name === 'sendInvite') {
            setUser({ ...user, [name]: value });
        }

        if (name === 'firstName') {
            if (value.trim() === '') {
                setErrors({
                    ...errors,
                    firstNameError: 'You are required to enter first name'
                });
                setUser({ ...user, firstName: value });
            } else if (value.trim().length < 2) {
                setErrors({
                    ...errors,
                    firstNameError: 'First name must be greater than one character'
                });
                setUser({ ...user, firstName: value });
            } else if (!nameRgx.test(value)) {
                setErrors({
                    ...errors,
                    firstNameError: 'Not a valid name'
                });
                setUser({ ...user, firstName: value });
            } else {
                setUser({ ...user, firstName: value });
                setErrors({
                    ...errors,
                    firstNameError: ''
                });
            }
        }

        if (name === 'lastName') {
            if (value.trim() === '') {
                setErrors({
                    ...errors,
                    lastNameError: 'You are required to enter last name'
                });
                setUser({ ...user, lastName: value });
            } else if (value.trim().length < 2) {
                setErrors({
                    ...errors,
                    lastNameError: 'Last name must be greater than one character'
                });
                setUser({ ...user, lastName: value });
            } else if (!nameRgx.test(value)) {
                setErrors({
                    ...errors,
                    lastNameError: 'Not a valid name'
                });
                setUser({ ...user, lastName: value });
            } else {
                setUser({ ...user, lastName: value });
                setErrors({
                    ...errors,
                    lastNameError: ''
                });
            }
        }
        if (name === 'email') {
            if (value.trim() === '') {
                setErrors({
                    ...errors,
                    emailError: 'You are required to enter email'
                });
                setUser({ ...user, email: value });
            } else if (!emailRgx.test(value)) {
                setErrors({
                    ...errors,
                    emailError: 'Please enter a valid email address'
                });
                setUser({ ...user, email: value });
            } else {
                setUser({ ...user, email: value });
                setErrors({
                    ...errors,
                    emailError: ''
                });
            }
        }
        if (name === 'phoneNumber') {
            let str = value.replace(/\D/g, '');

            if (value.trim() === '') {
                // setErrors({
                //     ...errors,
                //     phoneNumberError: 'You are required to enter phone number'
                // });
                setUser({ ...user, phoneNumber: `` });
                setFormattedNumber(value);
            } else if (str?.length !== 10) {
                setErrors({
                    ...errors,
                    phoneNumberError: 'You are required to enter a valid phone number'
                });
                setUser({ ...user, phoneNumber: `+1${str}` });
                setFormattedNumber(value);
            } else {
                setUser({ ...user, phoneNumber: `+1${str}` });
                setFormattedNumber(value);
                setErrors({
                    ...errors,
                    phoneNumberError: ''
                });
            }
        }
        if (name === 'department') {
            if (value.trim() === '') {
                setErrors({
                    ...errors,
                    departmentError: 'You are required to select department'
                });
                setUser({ ...user, department: value });
            } else {
                setUser({ ...user, department: value });
                setErrors({
                    ...errors,
                    departmentError: ''
                });
            }
        }
    };

    const CustomActionBar = () => (
        <Stack direction={'row'} justifyContent="space-around" sx={{ paddingBottom: 2 }}>
            <Button onClick={() => setUser({ ...user, sendInvite: dayjs() })}>Today</Button>
            {/* <Button onClick={() => setUser({ ...user, sendInvite: dayjs().addBusinessDays(2) })}>2 Business Days</Button>
            <Button onClick={() => setUser({ ...user, sendInvite: dayjs().addBusinessDays(5) })}>5 Business Days</Button> */}
        </Stack>
    );

    const handleOk = () => {
        setEditIndex(-1);
        const data = { ...user };
        handleAddItem(data);
        setFormattedNumber('');
        setUser({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            department: '',
            sendInvite: null
        });
    };

    // const renderValue = (date: any) => {
    //     if (date === dayjs()) {
    //         return 'Today';
    //     }
    //     if (date === dayjs().addBusinessDays(2)) {
    //         return '2 Business Days';
    //     }
    //     if (date === dayjs().addBusinessDays(5)) {
    //         return '5 Business Days';
    //     }
    //     return date;
    // };

    return (
        <>
            {/* <Grid container spacing={gridSpacing}> */}
            {/* <Stack m={4} direction="row" justifyContent={'space-evenly'} alignItems={'center'}> */}
            <Box m={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid
                    item
                    xs={12}
                    md={2}
                    m={1}
                    sx={{
                        width: 300
                    }}
                >
                    <Stack spacing={1} sx={{ maxWidth: '250px' }}>
                        <Typography variant="subtitle1">First Name</Typography>
                        <TextField
                            type="text"
                            name="firstName"
                            size="small"
                            value={firstName}
                            onChange={handleChange}
                            error={Boolean(firstNameError)}
                            helperText={firstNameError}
                            fullWidth
                            inputProps={{ maxLength: 32 }}
                            // disabled={!selectedItem.id}
                        />
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                    m={1}
                    sx={{
                        width: 300
                    }}
                >
                    <Stack spacing={1} sx={{ maxWidth: '250px' }}>
                        <Typography variant="subtitle1">Last Name</Typography>
                        <TextField
                            type="text"
                            name="lastName"
                            size="small"
                            value={lastName}
                            onChange={handleChange}
                            error={Boolean(lastNameError)}
                            helperText={lastNameError}
                            fullWidth
                            inputProps={{ maxLength: 32 }}
                            // disabled={!selectedItem.id}
                        />
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                    m={1}
                    sx={{
                        width: 300
                    }}
                >
                    <Stack spacing={1} sx={{ maxWidth: '250px' }}>
                        <Typography variant="subtitle1">Email</Typography>
                        <TextField
                            type="text"
                            name="email"
                            size="small"
                            value={email}
                            onChange={handleChange}
                            error={Boolean(emailError)}
                            helperText={emailError}
                            inputProps={{ maxLength: 52 }}
                            fullWidth
                            // disabled={!selectedItem.id}
                        />
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                    m={1}
                    sx={{
                        width: 300
                    }}
                >
                    <Stack spacing={1} sx={{ maxWidth: '250px' }}>
                        <Typography variant="subtitle1">Phone Number</Typography>
                        <PatternFormat
                            format="(###) ### ####"
                            value={obj?.phoneNumber || formattedNumber}
                            onChange={handleChange}
                            error={Boolean(phoneNumberError)}
                            helperText={phoneNumberError}
                            name="phoneNumber"
                            prefix="+1"
                            mask="_"
                            InputProps={{ size: 'small', fullWidth: true }}
                            customInput={TextField}
                        />
                    </Stack>
                </Grid>

                {departmentSetting && (
                    <Grid
                        item
                        xs={12}
                        md={2}
                        m={1}
                        sx={{
                            width: 300
                        }}
                    >
                        <Stack spacing={1} sx={{ maxWidth: '250px' }}>
                            <Typography variant="subtitle1">Department (optional)</Typography>
                            <TextField
                                id="outlined-select-currency"
                                name="department"
                                select
                                value={department}
                                onChange={handleChange}
                                error={Boolean(departmentError)}
                                helperText={departmentError}
                                fullWidth
                                size="small"
                            >
                                {departments?.map(
                                    (option: any) =>
                                        option.isActive && (
                                            <MenuItem key={option.name} value={option.name}>
                                                {option.name}
                                            </MenuItem>
                                        )
                                )}
                            </TextField>
                        </Stack>
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    md={2}
                    m={1}
                    sx={{
                        width: 300
                    }}
                >
                    <Stack spacing={1} sx={{ maxWidth: '250px' }}>
                        <Typography variant="subtitle1">Send Invite</Typography>
                        {share ? (
                            <TextField
                                disabled
                                type="text"
                                name="sharelink"
                                size="small"
                                value={'Share Link'}
                                onChange={handleChange}
                                error={false}
                                helperText={''}
                                fullWidth
                                inputProps={{ maxLength: 32 }}
                            />
                        ) : (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disablePast
                                    value={sendInvite}
                                    shouldDisableDate={(date: any) => {
                                        const monthLater = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
                                        return [0, 6].includes(dayjs(date).day()) || date > monthLater;
                                    }}
                                    //disable
                                    maxDate={new Date(now.getFullYear(), 11, 31)}
                                    PaperProps={dateTimePaperPropsStyles}
                                    onChange={(newValue: any) => {
                                        setUser({ ...user, sendInvite: newValue });
                                    }}
                                    components={{
                                        ActionBar: CustomActionBar
                                    }}
                                    componentsProps={{
                                        actionBar: { actions: ['clear', 'today', 'cancel', 'accept'] }
                                    }}
                                    renderInput={(params: any) => <TextField size="small" fullWidth name="sendInvite" {...params} />}
                                />
                            </LocalizationProvider>
                        )}
                    </Stack>
                </Grid>
                {!editUser ? (
                    <Grid item sx={{ display: 'flex', alignItems: 'end', height: '100px', pb: 1 }}>
                        <Stack direction="row">
                            <IconButton
                                disabled={
                                    firstName.trim() === '' ||
                                    firstName.trim().length < 2 ||
                                    lastName.trim() === '' ||
                                    lastName.trim().length < 2 ||
                                    (phoneNumber.length > 0 && phoneNumber.length < 12) ||
                                    !emailRgx.test(email) ||
                                    !nameRgx.test(firstName) ||
                                    !nameRgx.test(lastName)
                                }
                                onClick={handleOk}
                            >
                                <AddCircleIcon sx={{ fontSize: '42px' }} />
                            </IconButton>
                        </Stack>
                    </Grid>
                ) : (
                    <Grid item sx={{ display: 'flex', alignItems: 'end', height: '100px', pb: 2 }}>
                        <Stack direction="row">
                            <Tooltip title="Save">
                                <IconButton onClick={handleOk}>
                                    <DoneIcon sx={{ fontSize: '32px' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => {
                                        setEditIndex(-1);
                                        clearEdit();
                                    }}
                                >
                                    <RestoreIcon sx={{ fontSize: '32px' }} />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                )}
            </Box>
            {/* */}
        </>
    );
};

export default AddItemPage;
