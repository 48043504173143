// material-ui
import { Grid } from '@mui/material';

// project imports

import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

import navigation from 'menu-items';

// assets
import { IconChevronRight } from '@tabler/icons';
import { gridSpacing } from 'store/constant';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ search, addButton }: any) => {
    // const theme = useTheme();

    // const dispatch = useDispatch();
    // const { drawerOpen } = useSelector((state) => state.menu);
    return (
        <Grid
            container
            alignItems="center"
            justifyContent={'space-between'}
            spacing={gridSpacing}
            sx={{
                borderBottomColor: '#eaeaea',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                position: 'sticky',
                top: -10,
                bgcolor: '#fff',
                zIndex: 1000,
                px: '20px'
            }}
        >
            <Grid container item xs={6} alignItems="center" spacing={gridSpacing}>
                <Grid item>
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title />
                </Grid>
            </Grid>
            <Grid item>{search}</Grid>
            <Grid item>{addButton}</Grid>
        </Grid>
    );
};

export default Header;
