// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

interface Props {
    openAlert: boolean;
    handleAlertOpen: () => void;
    handleAlertClose: () => void;
    handleDrawerClose: () => void;
    handleAlertCloseClear: () => void;
    length: number;
}
const AlertDialog = ({ openAlert, handleAlertCloseClear, handleAlertClose, handleDrawerClose, length }: Props) => {
    const theme = useTheme();
    return (
        <>
            <Dialog
                open={openAlert}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
                PaperProps={{
                    style: {
                        backgroundColor: 'white',
                        boxShadow: 'none'
                    }
                }}
            >
                {openAlert && (
                    <>
                        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
                            Are you sure?
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="h4">You have unsaved users. Your changes will be deleted if you proceed.</Typography>
                                <Typography variant="h4" mt={2}>
                                    Do you want to continue?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                onClick={async () => {
                                    await handleDrawerClose();
                                    await handleAlertCloseClear();
                                }}
                                color="secondary"
                            >
                                Yes, I’m sure
                            </Button>
                            <Button variant="contained" size="small" onClick={handleAlertClose} autoFocus>
                                Cancel
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};
export default AlertDialog;
