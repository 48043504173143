// material-ui
import { Link, Typography, Stack, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import Logo from 'ui-component/Logo';
// import { Link as DomLink } from 'react-router-dom';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => {
    const theme = useTheme();
    return (
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Grid>
                <Typography variant="subtitle2" component={Link} href="https://getbrightup.com" target="_blank" underline="hover">
                    © 2022 BrightUp. All Rights Reserved
                </Typography>
            </Grid>
            <Stack direction="row">
                <Grid>
                    <Typography variant="subtitle1" color={theme.palette.secondary.main}>
                        <span
                            style={{
                                textUnderlineOffset: '4px',
                                marginLeft: '10px',
                                color: '#FB8500'
                            }}
                            onClick={() => {
                                const newWindow = window.open(
                                    'https://www.getbrightup.com/terms-and-conditions',
                                    '_blank',
                                    'noopener,noreferrer'
                                );
                                if (newWindow) newWindow.opener = null;
                            }}
                        >
                            Terms
                        </span>
                    </Typography>
                </Grid>
                <Grid>
                    <Typography variant="subtitle1" color={theme.palette.secondary.main}>
                        <span
                            style={{
                                textUnderlineOffset: '4px',
                                marginLeft: '10px',
                                color: '#FB8500'
                            }}
                            onClick={() => {
                                const newWindow = window.open(
                                    'https://www.getbrightup.com/privacy-policy',
                                    '_blank',
                                    'noopener,noreferrer'
                                );
                                if (newWindow) newWindow.opener = null;
                            }}
                        >
                            Privacy
                        </span>
                    </Typography>
                </Grid>
            </Stack>
        </Stack>
    );
};

export default AuthFooter;
