import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import JoinCompany from 'views/pages/authentication/JoinCompany';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));

const AcceptInvitation = Loadable(lazy(() => import('views/pages/authentication/AcceptInvitation')));
const ClientRegistration = Loadable(lazy(() => import('views/pages/authentication/ClientRegistration')));

const ForgotPasswordFlow = Loadable(lazy(() => import('views/pages/authentication/ForgotPasswordFlow')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <ClientRegistration />
        },
        {
            path: '/forgot-password',
            element: <ForgotPasswordFlow />
        },
        {
            path: '/accept-invitation',
            element: <AcceptInvitation />
        },
        {
            path: '/client-registration',
            element: <ClientRegistration />
        },
        {
            path: '/join-company',
            element: <JoinCompany />
        }
    ]
};

export default LoginRoutes;
