import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Redirect from './Redirect';
import AddUserHome from 'views/pages/userList/AddUserHome';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const UserList = Loadable(lazy(() => import('views/pages/userList')));
const AdminList = Loadable(lazy(() => import('views/pages/adminList')));
const AccountSettings = Loadable(lazy(() => import('views/pages/accountSettings')));
const FeedBack = Loadable(lazy(() => import('views/pages/Feedback')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/manage-users/add-user',
            element: <AddUserHome />
        },
        {
            path: '/manage-users/user-list',
            element: <UserList />
        },
        {
            path: 'informational-flyer',
            element: (
                <Redirect
                    url={
                        'https://storage.googleapis.com/brightup-dev.appspot.com/Downloadable%20Content/Onboarding%20Flyer%20Coming%20Soon%20(3).pdf'
                    }
                />
            )
        },
        {
            path: 'email-template',
            element: (
                <Redirect url={'https://storage.googleapis.com/brightup-dev.appspot.com/Downloadable%20Content/BU_Email%20Templates.pdf'} />
            )
        },
        {
            path: 'onboarding-guide',
            element: (
                <Redirect
                    url={'https://storage.googleapis.com/brightup-dev.appspot.com/Downloadable%20Content/Onboarding%20Welcome%20Guide.pdf'}
                />
            )
        },
        {
            path: '/manage-users/admin-list',
            element: <AdminList />
        },
        {
            path: '/account-settings',
            element: <AccountSettings />
        },
        {
            path: '/feedback',
            element: <FeedBack />
        }
    ]
};

export default MainRoutes;
