// material-ui

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets

import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Header from 'layout/MainLayout/Header';
import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import React from 'react';
import AddUser from './AddUser';

import { ReactComponent as EmailInvite } from '../../../assets/images/pages/email-invite.svg';
import { ReactComponent as LinkInvite } from '../../../assets/images/pages/link-invite.svg';
import DragDrop from '../adminList/DragDrop';

// ==============================|| USER LIST STYLE 1 ||============================== //

const AddUserHome = () => {
    const anchorRef = React.useRef<any>(null);

    // const { user } = useAuth();

    const [openModal, setOpenModal] = useState<any>({ state: false, upload: false, share: false });
    const [selectedOption, setSelectedOption] = useState(-1);
    // const [addUserList, setAddUserList] = React.useState<any>([]);
    const handleDrawerClose = () => {
        setOpenModal({ state: false, upload: false });
    };
    const [error, setError] = React.useState('');

    const handleClickOpen = () => {
        setOpenModal({ state: true, upload: false, share: selectedOption === 1 });
    };

    const handleToggle = (addUserList: any) => {
        setOpenModal({ state: true, upload: false, share: selectedOption === 1, csvData: addUserList });
    };

    const BtnEmailInvite = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    borderRadius: '12px',
                    border: selectedOption === 0 ? '1px solid #219EBC' : '1px solid #DCDCDC',
                    padding: '18px',
                    marginTop: '5px',
                    gap: '20px',
                    backgroundColor: selectedOption === 0 ? '#F4FAFC' : 'transparent'
                }}
            >
                <EmailInvite
                    stroke={selectedOption === 0 ? '#219EBC' : '#DCDCDC'}
                    fill={selectedOption === 1 ? '#219EBC' : '#DCDCDC'}
                    width={200}
                    height={150}
                />
                <Box>
                    <Typography variant="subtitle1" sx={{ fontSize: '18px', fontWeight: '700', textAlign: 'left' }}>
                        Receive an email invite from BrightUp
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'left'
                        }}
                        color={selectedOption === 0 ? '#000000' : '#00000080'}
                    >
                        You can control when they receive it by adding a date to the “SendInvite” field in the upload template. If this
                        option is selected and that field is left blank, the email invite will be sent the same day.
                    </Typography>
                </Box>
            </Box>
        );
    };

    const BtnShareLinkInvite = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    borderRadius: '12px',
                    border: selectedOption === 1 ? '1px solid #219EBC' : '1px solid #DCDCDC',
                    padding: '18px',
                    marginTop: '5px',
                    gap: '20px',
                    backgroundColor: selectedOption === 1 ? '#F4FAFC' : 'transparent'
                }}
            >
                <LinkInvite
                    stroke={selectedOption === 1 ? '#219EBC' : '#DCDCDC'}
                    fill={selectedOption === 1 ? '#219EBC' : '#DCDCDC'}
                    width={180}
                    height={150}
                />
                <Box>
                    <Typography variant="subtitle1" sx={{ fontSize: '18px', fontWeight: '700', textAlign: 'left' }}>
                        Share a link
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontSize: '16px', fontWeight: '400', textAlign: 'left' }}
                        color={selectedOption === 1 ? '#000000' : '#00000080'}
                    >
                        After uploading users, you will share your unique link (found under Account Settings) that directs them to set up
                        their BrightUp account. This method is ideal for a kickoff event, e-newsletter button, etc.
                    </Typography>
                </Box>
            </Box>
        );
    };

    const addButton = (
        <Button onClick={handleClickOpen} ref={anchorRef} variant="outlined" startIcon={<AddIcon />}>
            Add a Single User
        </Button>
    );

    const cancelButton = (
        <Button onClick={handleClickOpen} variant="outlined" style={{ width: '196px', fontSize: '16px', fontWeight: '700' }}>
            Cancel
        </Button>
    );

    const doneButton = (
        <Button onClick={handleClickOpen} variant="contained" style={{ width: '196px', fontSize: '16px', fontWeight: '700' }}>
            Done
        </Button>
    );
    return (
        <>
            <Header addButton={addButton} />
            {/* <Label>sfsdfg</Label> */}

            <AddUser
                open={openModal}
                handleClickOpen={() => setOpenModal({ state: false, upload: false, share: selectedOption === 1 })}
                handleToggle={() => setOpenModal((prev: any) => ({ ...prev, upload: !prev.upload }))}
                handleDrawerClose={handleDrawerClose}
            />
            <MainCard sx={{ m: 4 }}>
                <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: '700' }}>
                    Upload Users
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '18px', fontWeight: '700' }}>
                    How do you want to invite these users to join BrightUp?
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: '400' }}>
                    Users must use one of these methods to create an account to receive the benefits you’re providing them.
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <IconButton
                            onClick={() => setSelectedOption(0)}
                            size="medium"
                            sx={{
                                borderRadius: '0px',
                                '& .MuiTouchRipple-child': { borderRadius: 'inherit' }
                            }}
                        >
                            <BtnEmailInvite />
                        </IconButton>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <IconButton
                            onClick={() => setSelectedOption(1)}
                            size="medium"
                            sx={{
                                borderRadius: '0px',
                                '& .MuiTouchRipple-child': { borderRadius: 'inherit' }
                            }}
                        >
                            <BtnShareLinkInvite />
                        </IconButton>
                    </Grid>
                </Grid>
                <Typography style={{ color: '#5B616B', fontStyle: 'oblique', fontSize: '14px', fontWeight: '400' }}>
                    Note: If you select “Receive an Email”, you can share the link also.
                </Typography>

                <Box
                    sx={{
                        justifyContent: 'center',
                        alignContent: 'center',
                        width: '100%',
                        flexDirection: 'column'
                    }}
                >
                    <Typography variant="h4" textAlign="left" lineHeight={1} my={3}>
                        <Link
                            download
                            href="https://storage.googleapis.com/brightup-dev.appspot.com/Client/ClientDashboardAssets/sample.csv"
                        >
                            Download this template
                        </Link>{' '}
                        and add your list of users, then upload it as a .csv file below.
                    </Typography>
                    <Typography
                        style={{ color: '#5B616B', fontStyle: 'oblique', fontSize: '14px', fontWeight: '400', marginBottom: '10px' }}
                    >
                        The following columns are optional: Department, Phone Number, SendInvite. ("SendInvite" refers to the date
                        (month/day/year) in the future when you want to invite your users to join BrightUp. If you’re unsure, or don’t want
                        to send an invite, leave it blank.)
                    </Typography>
                    <DragDrop
                        setData={async (res: any) => {
                            const newData = res.map((i: any) => ({ ...i, id: Math.random() }));

                            handleToggle(newData);
                        }}
                        setError={setError}
                    />
                </Box>
                <Typography variant="h4" color="error" textAlign="center" mt={3}>
                    {error}
                </Typography>
                <Grid container style={{ justifyContent: 'flex-end', gap: '20px' }}>
                    <Grid item xs={6} md={2}>
                        {cancelButton}
                    </Grid>
                    <Grid item xs={6} md={2}>
                        {doneButton}
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default AddUserHome;
