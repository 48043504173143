// material-ui
import {
    Box,
    Button,
    InputLabel,
    OutlinedInput,
    Typography,
    Stack,
    Link,
    ButtonBase,
    FormHelperText,
    InputAdornment,
    IconButton
} from '@mui/material';
import { useDispatch } from 'store';
import { useLocation } from 'react-router-dom';
import { ReactComponent as AppleDownload } from '../../../../assets/apple.svg';
import { ReactComponent as GoogleDownload } from '../../../../assets/google.svg';

// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// third party
import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAuth from 'hooks/useAuth';
import { openSnackbar } from 'store/slices/snackbar';
import { useState } from 'react';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { StringColorProps } from 'types';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// ========================|| FIREBASE - FORGOT PASSWORD ||======================== //

const JoinWithInviteLink = ({
    onVerificationStatus,
    onUpdateSuccess
}: {
    onVerificationStatus: (status: boolean, msg: string) => void;
    onUpdateSuccess: (value: boolean) => void;
}) => {
    let location = useLocation();

    const dispatch = useDispatch();

    const search = location.search;
    const cid = new URLSearchParams(search).get('cid') || '';
    // const existingUserId = new URLSearchParams(search).get('existingUserId');
    // const invitationCode = new URLSearchParams(search).get('code') || '';
    // const clientId = new URLSearchParams(search).get('clientId') || '';

    const [tempEmail, setTempEmail] = useState('');
    // console.log('existingUserId', cid);
    // const email = tempEmail.toString().includes(' ') ? tempEmail.toString().replace(/ /g, '+') : tempEmail;

    const { CheckJoinEmail, acceptInvitation } = useAuth();

    const [success, setSuccess] = useState<boolean>(false);
    const [emailVerifySuccess, setEmailVerifySuccess] = useState<boolean>(false);
    const [emailError, setEmailError] = useState('');
    const [shortToken, setShortToken] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const passwordRegx = /^(?=.*\d)(?=.*[!@#$%^&*()-])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

    const [, setStrength] = useState(0);
    const [, setLevel] = useState<StringColorProps>();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.SyntheticEvent) => {
        event.preventDefault();
    };
    const changePassword = (value: string) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    const validateEmail = (e: string) => {
        if (e && isValidEmail.test(e)) {
            return false;
        }
        return true;
    };

    return success ? (
        <Stack justifyContent={'center'} alignItems="center">
            {/* <CheckCircleOutlineIcon fontSize="large" color="success" /> */}
            <Typography textAlign={'center'} fontSize={14} color="black">
                Download the BrightUp app and login to begin your journey to improved financial wellbeing.
            </Typography>
            <Stack direction={'row'}>
                <ButtonBase
                    sx={{ width: 200, height: 100 }}
                    LinkComponent={Link}
                    target="_blank"
                    href="https://apps.apple.com/us/app/brightup-financial-wellness/id1638340315"
                    color="secondary.main"
                >
                    <AppleDownload />
                </ButtonBase>
                <ButtonBase
                    sx={{ width: 200, height: 100 }}
                    LinkComponent={Link}
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.tr.brightup&pli=1"
                    color="secondary.main"
                >
                    <GoogleDownload />
                </ButtonBase>
            </Stack>
        </Stack>
    ) : emailVerifySuccess ? (
        <Formik
            initialValues={{
                password: '',
                submit: null
            }}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    setIsLoading(true);
                    await acceptInvitation(tempEmail, values.password, shortToken, cid);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Thank you for completing registration please proceed to the mobile app in order to login.',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    setSuccess(true);
                    setIsLoading(false);
                    onUpdateSuccess(true);
                } catch (err: any) {
                    setIsLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: err?.ex,
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            variant: 'alert',
                            severity: 'error',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <InputLabel sx={{ my: 1, color: '#002443' }} htmlFor="outlined-adornment-email-forgot">
                        Email Address
                    </InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <OutlinedInput
                            id="outlined-adornment-email-forgot"
                            type="email"
                            size="small"
                            value={tempEmail}
                            name="email"
                            fullWidth
                            readOnly
                            inputProps={{}}
                        />
                    </Box>
                    <InputLabel sx={{ my: 1, color: '#002443' }} htmlFor="outlined-adornment-password-register">
                        Create Password
                    </InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <OutlinedInput
                            id="outlined-adornment-password-register"
                            size="small"
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            name="password"
                            style={{ marginRight: '5px' }}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={(e) => {
                                handleChange(e);
                                changePassword(e.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            inputProps={{}}
                        />
                        {!passwordRegx.test(values.password) || values.password.trim() === '' ? (
                            <Box sx={{ display: 'none' }}>
                                {' '}
                                <CheckCircleIcon color="success" />
                            </Box>
                        ) : (
                            <CheckCircleIcon color="success" />
                        )}
                    </Box>
                    {!passwordRegx.test(values.password) && values.password.trim() !== '' ? (
                        <>
                            <FormHelperText error sx={{ mr: '30px' }}>
                                Your password must contain:
                            </FormHelperText>
                            <FormHelperText error sx={{ mr: '30px' }}>
                                <span
                                    style={{
                                        width: '4px',
                                        height: '4px',
                                        background: 'red',
                                        display: 'inline-block',
                                        borderRadius: '100%',
                                        marginBottom: '2px',
                                        marginInline: '10px'
                                    }}
                                ></span>{' '}
                                Minimum of 6 characters,
                            </FormHelperText>
                            <FormHelperText error sx={{ mr: '30px' }}>
                                <span
                                    style={{
                                        width: '4px',
                                        height: '4px',
                                        background: 'red',
                                        display: 'inline-block',
                                        borderRadius: '100%',
                                        marginBottom: '2px',
                                        marginInline: '10px'
                                    }}
                                ></span>{' '}
                                At least 1 lower case
                            </FormHelperText>
                            <FormHelperText error sx={{ mr: '30px' }}>
                                <span
                                    style={{
                                        width: '4px',
                                        height: '4px',
                                        background: 'red',
                                        display: 'inline-block',
                                        borderRadius: '100%',
                                        marginBottom: '2px',
                                        marginInline: '10px'
                                    }}
                                ></span>{' '}
                                At least 1upper case
                            </FormHelperText>
                            <FormHelperText error sx={{ mr: '30px' }}>
                                <span
                                    style={{
                                        width: '4px',
                                        height: '4px',
                                        background: 'red',
                                        display: 'inline-block',
                                        borderRadius: '100%',
                                        marginBottom: '2px',
                                        marginInline: '10px'
                                    }}
                                ></span>{' '}
                                At least 1 number
                            </FormHelperText>
                            <FormHelperText error sx={{ mr: '30px' }}>
                                <span
                                    style={{
                                        width: '4px',
                                        height: '4px',
                                        background: 'red',
                                        display: 'inline-block',
                                        borderRadius: '100%',
                                        marginBottom: '2px',
                                        marginInline: '10px'
                                    }}
                                ></span>{' '}
                                At least 1 special character
                            </FormHelperText>
                            <FormHelperText error sx={{ mr: '30px' }}>
                                <span
                                    style={{
                                        width: '4px',
                                        height: '4px',
                                        background: 'red',
                                        display: 'inline-block',
                                        borderRadius: '100%',
                                        marginBottom: '2px',
                                        marginInline: '10px'
                                    }}
                                ></span>{' '}
                                No spaces
                            </FormHelperText>
                        </>
                    ) : null}
                    <InputLabel sx={{ my: 1, color: '#002443' }} htmlFor="confirm-password">
                        Confirm Password
                    </InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <OutlinedInput
                            id="confirm-password"
                            size="small"
                            type={showConfirmPassword ? 'text' : 'password'}
                            fullWidth
                            style={{ marginRight: '5px' }}
                            name="Confirm Password"
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large"
                                    >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {values.password !== confirmPassword || confirmPassword.trim() === '' ? (
                            <Box sx={{ display: 'none' }}>
                                {' '}
                                <CheckCircleIcon color="success" />
                            </Box>
                        ) : (
                            <CheckCircleIcon color="success" />
                        )}
                    </Box>
                    {values.password !== confirmPassword && confirmPassword.trim() !== '' ? (
                        <FormHelperText error>Password does not match</FormHelperText>
                    ) : null}

                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                // disabled={isLoading}
                                disabled={
                                    isLoading || values.password === '' || values.password !== confirmPassword || validateEmail(tempEmail)
                                }
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ color: '#fff' }}
                            >
                                {'Create My Account'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    ) : (
        <Formik
            initialValues={{
                password: '',
                submit: null
            }}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    setIsLoading(true);
                    console.log(values);
                    setShortToken('');
                    const response = await CheckJoinEmail(tempEmail, cid);
                    setIsLoading(false);
                    if (response.data.status === true) {
                        // dispatch(
                        //     openSnackbar({
                        //         open: true,
                        //         message: 'Thank you for completing registration please proceed to the mobile app in order to login.',
                        //         anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        //         variant: 'alert',
                        //         alert: {
                        //             color: 'success'
                        //         },
                        //         close: false
                        //     })
                        // );
                        setShortToken(response.data.invitationCode);
                        setEmailVerifySuccess(true);
                        onVerificationStatus(true, '');
                        setEmailError('');
                    } else {
                        openSnackbar({
                            open: true,
                            message: 'Failed to Verify',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            variant: 'alert',
                            severity: 'error',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        });
                        setEmailError(response.data.message);
                        onVerificationStatus(false, response.data.message);
                    }
                } catch (err: any) {
                    setEmailError('');
                    setIsLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: err?.ex,
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            variant: 'alert',
                            severity: 'error',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <InputLabel sx={{ my: 1, color: '#002443' }} htmlFor="outlined-adornment-email-forgot">
                        Email Address
                    </InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <OutlinedInput
                            id="outlined-adornment-email-forgot"
                            type="email"
                            size="small"
                            value={tempEmail}
                            onChange={(val) => {
                                setEmailError('');
                                setTempEmail(val.target.value);
                            }}
                            name="email"
                            fullWidth
                            inputProps={{}}
                        />
                    </Box>
                    <InputLabel sx={{ my: 1, color: 'red' }} htmlFor="outlined-adornment-email-forgot">
                        {emailError}
                    </InputLabel>
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ color: '#fff' }}
                            >
                                Continue
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default JoinWithInviteLink;
