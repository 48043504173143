import * as React from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Link, Typography } from '@mui/material';
import ProductsPage from './ProductsPage';
import AddItemPage from './AddItemPage';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DragDrop from '../adminList/DragDrop';
import { useDispatch, useSelector } from 'store';
import { addUsers, userSelector } from 'store/slices/user';
import AlertDialog from './AlertDialog';
import useAuth from 'hooks/useAuth';
import { accountSettingSelector, getDepartmentList } from 'store/slices/accountSetting';
import { useEffect } from 'react';

interface Props {
    open: any;
    handleClickOpen: () => void;
    handleDrawerClose: () => void;
    handleToggle: () => void;
}

const AddUser = ({ open, handleDrawerClose, handleToggle }: Props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDepartmentList({ clientId: user?.organizations?.clientId }));
    }, []);
    const {
        departmentList: {
            data: { departmentSetting, departments }
        }
    } = useSelector(accountSettingSelector);
    const [setAddItemClicked] = React.useState<boolean>(false);
    const [error, setError] = React.useState('');
    const { allUsers } = useSelector(userSelector);

    const { isSuccess, isFetching } = allUsers as any;

    const { user } = useAuth();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [edit, setEdit] = React.useState<any>({});
    const [editUser, setEditUser] = React.useState<any>(false);
    const [editIndex, setEditIndex] = React.useState<any>(-1);

    const handleAlertOpen = () => {
        setOpenAlert(true);
    };
    const handleAlertClose = () => {
        setOpenAlert(false);
    };

    const [addUserList, setAddUserList] = React.useState<any>([]);

    // to delete row in order details
    const deleteProductHandler = (id: number) => {
        setAddUserList(addUserList.filter((item: any) => item?.id !== id));
    };

    // add item handler
    const handleAddItem = (addingData: any) => {
        if (editUser) {
            const objIndex = addUserList.findIndex((obj: any) => obj.id === edit[0].id);

            const copy = addUserList;
            copy[objIndex] = addingData;

            setAddUserList([...copy]);
            setEdit({});
            setEditUser(false);
        } else {
            setAddUserList([
                ...addUserList,
                {
                    id: Math.random(),
                    firstName: addingData.firstName,
                    department: addingData.department,
                    lastName: addingData.lastName,
                    email: addingData.email,
                    phoneNumber: addingData.phoneNumber,
                    sendInvite: open?.share ? '' : addingData.sendInvite ?? ''
                }
            ]);
        }
    };

    useEffect(() => {
        if (open?.csvData) {
            setAddUserList(open?.csvData);
        }
    }, [open?.csvData]);

    const handleEdit = (id: any) => {
        setEdit(addUserList.filter((item: any) => item.id === id));
        setEditUser(true);
    };
    const clearEdit = () => {
        setEdit({});
        setEditUser(false);
    };

    React.useEffect(() => {
        if (isSuccess) {
            setAddUserList([]);
            handleAlertClose();
        }
    }, [isSuccess]);

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={open.state}
                PaperProps={{
                    style: {
                        backgroundColor: 'white',
                        boxShadow: 'none'
                    }
                }}
            >
                {open.state && (
                    <>
                        {!open?.upload ? (
                            <>
                                <Box sx={{ position: 'absolute', right: '15px' }}>
                                    <IconButton
                                        onClick={() => {
                                            if (addUserList.length > 0) {
                                                handleAlertOpen();
                                            } else handleDrawerClose();
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                                <Grid container p={3} pr={12} justifyContent="space-between">
                                    <Grid item xs={7}>
                                        <Typography variant="h2">Add New Users</Typography>
                                    </Grid>
                                    <Grid container alignSelf={'flex-end'} item xs={'auto'}>
                                        <Grid item>
                                            <Button
                                                startIcon={<AddIcon />}
                                                component="label"
                                                sx={{ border: '2px solid black' }}
                                                onClick={() => {
                                                    handleToggle();
                                                }}
                                            >
                                                Upload a File
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <DialogContent>
                                    <ProductsPage
                                        departmentsEnabled={departmentSetting}
                                        departments={departments?.map((item: any) => item.isActive && item.name)}
                                        productsData={addUserList}
                                        deleteProductHandler={deleteProductHandler}
                                        handleEdit={handleEdit}
                                        editUser={editUser}
                                        editIndex={editIndex}
                                        setEditIndex={setEditIndex}
                                        share={open?.share}
                                    />
                                    <Grid item xs={12}>
                                        <AddItemPage
                                            handleAddItem={handleAddItem}
                                            setAddItemClicked={setAddItemClicked}
                                            edit={edit}
                                            editUser={editUser}
                                            clearEdit={clearEdit}
                                            setEditIndex={setEditIndex}
                                            share={open?.share}
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions sx={{ pr: 6 }}>
                                    <Button
                                        sx={{ color: 'black', borderColor: 'black', fontWeight: '700' }}
                                        onClick={() => {
                                            if (addUserList.length > 0) {
                                                handleAlertOpen();
                                            } else handleDrawerClose();
                                        }}
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        sx={{ margin: '0 10px', paddingBlock: '7px', paddingInline: '50px' }}
                                        variant="contained"
                                        color="primary"
                                        disabled={addUserList.length < 1 || isFetching || editUser}
                                        onClick={async () => {
                                            const formattedUserList = addUserList.map((addedUSer: any) => ({
                                                ...addedUSer,
                                                phoneNumber: addedUSer.phoneNumber.startsWith('+1')
                                                    ? addedUSer.phoneNumber.slice(2)
                                                    : addedUSer.phoneNumber
                                            }));

                                            await dispatch(
                                                addUsers({
                                                    users: formattedUserList,
                                                    clientId: user?.organizations?.clientId
                                                })
                                            );
                                            handleDrawerClose();
                                        }}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            </>
                        ) : (
                            <>
                                <Box sx={{ position: 'absolute', right: '15px' }}>
                                    <IconButton onClick={handleDrawerClose}>
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                                <Grid container m={3} pr={12} justifyContent="space-between">
                                    <Typography variant="h2">Add users by uploading a file</Typography>
                                    <Grid item>
                                        <Button
                                            startIcon={<AddIcon />}
                                            component="label"
                                            sx={{ border: '2px solid black' }}
                                            onClick={() => {
                                                handleToggle();
                                            }}
                                        >
                                            Add a Single User
                                        </Button>
                                    </Grid>
                                </Grid>

                                <DialogContent>
                                    <Box
                                        sx={{
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            width: '100%',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Typography variant="h4" textAlign="left" lineHeight={1.5} my={3}>
                                            <strong>Note: </strong>
                                            <Link
                                                download
                                                href="https://storage.googleapis.com/brightup-dev.appspot.com/Client/ClientDashboardAssets/sample.csv"
                                            >
                                                Download this template
                                            </Link>{' '}
                                            and add your team member information to each column. Note: The “Department” and “SendInvite”
                                            columns are optional. “SendInvite” can include the date (month/day/year) in the future when you
                                            want to send the BrightUp invite. Upload the template back here when it’s ready!
                                        </Typography>
                                        <DragDrop
                                            setData={async (res: any) => {
                                                const newData = res.map((i: any) => ({ ...i, id: Math.random() }));

                                                setAddUserList([...addUserList, ...newData]);
                                                handleToggle();
                                            }}
                                            setError={setError}
                                        />
                                    </Box>
                                    <Typography variant="h4" color="error" textAlign="center" mt={3}>
                                        {error}
                                    </Typography>
                                </DialogContent>
                            </>
                        )}
                    </>
                )}
                <AlertDialog
                    openAlert={openAlert}
                    handleAlertOpen={handleAlertOpen}
                    handleAlertClose={handleAlertClose}
                    handleAlertCloseClear={() => {
                        handleAlertClose();
                        setAddUserList([]);
                    }}
                    length={addUserList.length}
                    handleDrawerClose={handleDrawerClose}
                />
            </Dialog>
        </>
    );
};

export default AddUser;
