// material-ui
import { Box } from '@mui/material';

// project import
import { useTheme } from '@mui/material/styles';
import MainCard, { MainCardProps } from 'ui-component/cards/MainCard';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children, ...other }: MainCardProps) => {
    const theme = useTheme();
    return (
        <MainCard
            sx={{
                maxWidth: { xs: 400, lg: 475 },
                margin: { xs: 2.5, md: 3 },
                backgroundColor: theme.palette.background.default,

                '& > *': {
                    flexGrow: 1,
                    flexBasis: '50%'
                }
            }}
            content={false}
            {...other}
        >
            <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
        </MainCard>
    );
};

export const ClientRegistrationCardWrapper = ({ children, ...other }: MainCardProps) => {
    return (
        <MainCard content={true} {...other} sx={{ minHeight: '80vh' }}>
            <Box>{children}</Box>
        </MainCard>
    );
};

export default AuthCardWrapper;
