// material-ui
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

// import DoneIcon from '@mui/icons-material/Done';
// import RestoreIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { formatPhoneNumber } from 'utils/helper';
import { Stack } from '@mui/system';

// ==============================|| PRODUCTS-DATA PAGE ||============================== //

const ProductsPage = ({
    productsData,
    deleteProductHandler,
    departmentsEnabled,
    handleEdit,
    editIndex,
    setEditIndex,
    editUser,
    departments,
    share
}: any) => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const returnColor = (date: any) => {
        const incomingDate = new Date(date).setHours(0, 0, 0, 0);
        return new Date(date).toLocaleDateString() === 'Invalid Date' || incomingDate < currentDate ? 'red' : '#000';
    };
    return (
        <>
            {productsData.length ? (
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ pl: 3 }}>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    {departmentsEnabled && <TableCell>Department</TableCell>}
                                    {share ? <></> : <TableCell>Send Invite Date</TableCell>}
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productsData.map((row: any, index: any) => (
                                    <TableRow key={index} style={{ backgroundColor: index === editIndex ? '#f2f2f2' : '' }}>
                                        <TableCell sx={{ pl: 3 }}>
                                            <Typography align="left" variant="subtitle1">
                                                {row.firstName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{row.lastName}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{formatPhoneNumber(row?.phoneNumber)}</TableCell>
                                        {departmentsEnabled && (
                                            <TableCell
                                                sx={{
                                                    color: !departments.includes(row?.department) ? 'red' : '#000'
                                                }}
                                            >
                                                {row.department}
                                            </TableCell>
                                        )}
                                        {share ? (
                                            <></>
                                        ) : (
                                            <TableCell
                                                sx={{
                                                    color: returnColor(row?.sendInvite)
                                                }}
                                            >
                                                {row?.sendInvite ? new Date(row?.sendInvite).toLocaleDateString() : ''}
                                            </TableCell>
                                        )}
                                        <TableCell sx={{ pr: 1 }}>
                                            {editUser && editIndex !== -1 ? (
                                                ''
                                            ) : (
                                                <Stack direction="row">
                                                    <IconButton color="error" size="small" onClick={() => deleteProductHandler(row.id)}>
                                                        <DeleteTwoToneIcon fontSize="small" />
                                                    </IconButton>

                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            handleEdit(row.id);
                                                            setEditIndex(index);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            {/* <TableBody>
                                {rows.map((row: any, index: any) => (
                                    <TableRow key={index}>
                                        <CustomTableCell {...{ row, name: 'firstName', onChange, type: 'text' }} />
                                        <CustomTableCell {...{ row, name: 'lastName', onChange, type: 'text' }} />
                                        <CustomTableCell {...{ row, name: 'email', onChange, type: 'text' }} />
                                        <CustomTableCell {...{ row, name: 'phoneNumber', onChange, type: 'text' }} />
                                        {departmentsEnabled && (
                                            <CustomTableCell {...{ row, name: 'department', onChange, type: 'text' }} />
                                        )}{' '}
                                        <CustomTableCell {...{ row, name: 'sendInvite', onChange, type: 'date' }} />
                                        <TableCell sx={{ pr: 1 }}>
                                            <IconButton color="error" size="small" onClick={() => deleteProductHandler(row.id)}>
                                                <DeleteTwoToneIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {row.isEditMode ? (
                                                <>
                                                    <IconButton aria-label="done" onClick={() => onToggleEditMode(row.id)}>
                                                        <DoneIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="revert" onClick={() => onRevert(row.id)}>
                                                        <RestoreIcon />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <IconButton aria-label="delete" onClick={() => onToggleEditMode(row.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody> */}
                        </Table>
                    </TableContainer>
                </Grid>
            ) : null}
        </>
    );
};

export default ProductsPage;
